import { BookDetails } from "./model";

export function parseAmazonBook(html: string, url: string): BookDetails {
  const dom = new DOMParser().parseFromString(html, "text/html");
  const title = dom.querySelector("#productTitle")?.textContent || "";
  const authors = new Set<string>();
  dom
    .querySelectorAll(".author > .a-link-normal")
    .forEach((e) => authors.add(e.textContent?.trim() || ""));
  dom
    .querySelectorAll(".authorCarousel > .a-link-normal")
    .forEach((e) => authors.add(e.textContent?.trim() || ""));
  authors.delete("");

  if (authors.size === 0) {
    dom.querySelectorAll(".a-link-normal.a-text-normal").forEach((e) => {
      const parent = e.parentElement;
      if (
        e.classList.contains("authorNameLink") ||
        (parent &&
          Array.from(parent.classList).some((s) =>
            s.startsWith("_follow-the-author-card_style_authorNameColumn")
          ))
      ) {
        authors.add(e.textContent?.trim() || "");
      }
    });
  }
  dom
    .querySelectorAll(".contributorNameID")
    .forEach((e) => authors.add(e.textContent?.trim() || ""));
  authors.delete("");

  const description =
    dom.querySelector("#bookDescription_feature_div .a-expander-content")
      ?.innerHTML || "";

  const cover = dom.querySelector("#imgBlkFront")?.getAttribute("src") || "";

  const ISBNs = new Set<string>();
  let isISBN = false;
  dom.querySelectorAll("#detailBullets_feature_div span").forEach((e) => {
    if (e.childElementCount === 0) {
      const text = e.textContent?.trim() || "";
      if (isISBN && text.length > 0) ISBNs.add(text);
      isISBN = text.startsWith("ISBN-");
    }
  });

  const queryStart = url.indexOf("?");
  return {
    Authors: Array.from(authors),
    Links: [queryStart < 0 ? url : url.substring(0, queryStart)],
    Title: title.trim(),
    ISBNs: Array.from(ISBNs),
    Cover: cover,
    Description: description.trim(),
  };
}
