import { LoadingIcon } from "@rescui/icons";
import { Input } from "@rescui/input";
import i18next from "i18next";
import React, { useRef, useState } from "react";

import { parseAmazonBook } from "./BookParser";
import { BookDetails } from "./model";

export function EditBookDetails(props: {
  forAdding: boolean;
  book: BookDetails;
  onChange: (updated: BookDetails) => void;
}) {
  const book = props.book;
  const authorString = book.Authors.join(", ");
  const linkString = book.Links.join(", ");
  const isbnString = book.ISBNs.join(", ");

  const editTitle = useRef<HTMLInputElement>(null);
  const editAuthors = useRef<HTMLInputElement>(null);
  const editDescription = useRef<HTMLTextAreaElement>(null);
  const editISBNs = useRef<HTMLInputElement>(null);
  const editLinks = useRef<HTMLInputElement>(null);
  const editCover = useRef<HTMLInputElement>(null);

  const coverUrl = editCover.current ? editCover.current.value : book.Cover;

  const update = () =>
    props.onChange({
      Title: editTitle.current!.value,
      Authors: editAuthors
        .current!.value.split(",")
        .map((s) => s.trim())
        .filter((s) => s.length > 0),
      Links: editLinks
        .current!.value.split(",")
        .map((s) => s.trim())
        .filter((s) => s.length > 0),
      Description: editDescription.current!.value,
      ISBNs: editISBNs
        .current!.value.split(",")
        .map((s) => s.trim())
        .filter((s) => s.length > 0),
      Cover: editCover.current!.value,
    });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const links = (
    <div className="links">
      {i18next.t("Links (comma-separated)")}:<br />
      <div className="links-and-loading">
        <div className="links-input">
          <Input
            mode="rock"
            size="s"
            ref={editLinks}
            defaultValue={linkString}
            error={error ? "Amazon not available" : false}
            onChange={(e) => {
              update();
              const linksValue = e.target.value;
              const url = linksValue.trim();
              if (url.indexOf(".amazon.") > 0 && url.indexOf(",") < 0) {
                setLoading(true);
                fetch(
                  process.env.REACT_APP_BOOK_API_URL +
                    "/view/external_book_details?url=" +
                    url,
                  { method: "GET", credentials: "include" }
                )
                  .then((res) =>
                    res.status === 200
                      ? Promise.resolve(res.text())
                      : Promise.reject(res.text())
                  )
                  .then((body) => {
                    const parsed = parseAmazonBook(body, url);
                    if (editLinks.current!.value === linksValue)
                      editLinks.current!.value = parsed.Links.join(", ");
                    if (editTitle.current!.value === "")
                      editTitle.current!.value = parsed.Title;
                    if (editAuthors.current!.value === "")
                      editAuthors.current!.value = parsed.Authors.join(", ");
                    if (editCover.current!.value === "")
                      editCover.current!.value = parsed.Cover;
                    if (editDescription.current!.value === "")
                      editDescription.current!.value = parsed.Description;
                    if (editISBNs.current!.value === "")
                      editISBNs.current!.value = parsed.ISBNs.join(", ");
                    update();
                  })
                  .catch(() => setError(true))
                  .finally(() => setLoading(false));
              }
            }}
          />
        </div>
        {loading && <LoadingIcon />}
      </div>
    </div>
  );

  return (
    <div className="edit-book-details">
      {coverUrl && (
        <div className="cover">
          <img
            src={coverUrl}
            alt={i18next.t("Book cover")}
            className="cover-image"
          />
        </div>
      )}
      {props.forAdding && links}
      <div className="title">
        {i18next.t("Book title")}:
        <Input
          mode="rock"
          size="s"
          ref={editTitle}
          defaultValue={book.Title}
          onChange={update}
        />
      </div>
      <div className="authors">
        {i18next.t("Book authors (comma-separated)")}:
        <Input
          mode="rock"
          size="s"
          ref={editAuthors}
          defaultValue={authorString}
          onChange={update}
        />
      </div>
      <div className="cover-url">
        {i18next.t("Cover image URL")}:
        <Input
          mode="rock"
          size="s"
          ref={editCover}
          defaultValue={book.Cover}
          onChange={update}
        />
      </div>
      <div className="description">
        {i18next.t("Book description (HTML allowed)")}:<br />
        <textarea
          ref={editDescription}
          defaultValue={book.Description}
          rows={10}
          onChange={update}
        />
      </div>
      <div className="isbn">
        {i18next.t("ISBNs (comma-separated)")}:<br />
        <Input
          mode="rock"
          size="s"
          ref={editISBNs}
          defaultValue={isbnString}
          onChange={update}
        />
      </div>
      {!props.forAdding && links}
    </div>
  );
}
