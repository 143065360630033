import { Component } from "react";

export interface LazyListState<T> {
  items: T[];
  loading: boolean;
  complete: boolean;
}

export class LazyList<T, S extends LazyListState<T>> {
  private readonly fetch: (
    last_loaded_id: number,
    batch_size: number
  ) => Promise<T[]>;
  protected readonly batch_size: number;
  protected last_loaded_id: number = -1;
  private readonly id_field: (item: T) => number;
  protected readonly component: Component<any, S>;

  constructor(
    component: Component<any, S>,
    fetch: (last_loaded_id: number, batch_size: number) => Promise<T[]>,
    batch_size: number,
    id_field: (item: T) => number
  ) {
    this.component = component;
    this.fetch = fetch;
    this.batch_size = batch_size;
    this.id_field = id_field;
  }

  protected doFetch: () => Promise<T[]> = () => {
    return this.fetch(this.last_loaded_id, this.batch_size).then(
      (new_items) => {
        if (new_items.length !== 0) {
          this.last_loaded_id = this.id_field(new_items[new_items.length - 1]);
        }
        return new_items;
      }
    );
  };

  loadMore: () => void = () => {
    this.component.setState({ loading: true });
    this.doFetch().then((new_items: T[]) =>
      this.component.setState((prevState) => ({
        complete: new_items.length < this.batch_size,
        loading: false,
        items: prevState.items.concat(new_items),
      }))
    );
  };
}
