import i18next from "i18next";

import en from "./i18n/en/resource.json";

export default i18next.init({
  initImmediate: true,
  defaultNS: "resource",
  fallbackLng: "en",
  resources: { en: { resource: en } },
});
