export function parse(input: string): number {
  const match = input.match(/^(?<hours>\d+)(:(?<minutes>\d+))?$/);

  if (match === null || !match.groups) {
    throw new Error(`Invalid duration: ${input}`);
  }

  const hours = Number.parseInt(match.groups.hours);
  const minutes = Number.parseInt(match.groups.minutes ?? "0");

  return (hours * 60 + minutes) * 60000; // ms
}

export function format(ms: number): string {
  const hours = Math.floor(ms / 3600_000);
  const minutes = Math.floor((ms % 3600_000) / 60000);
  return `${hours}:${minutes.toString().padStart(2, "0")}`;
}
