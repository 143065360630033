import { useEffect } from "react";

export default function useDocumentTitle(title: string) {
  useEffect(() => {
    const original = document.title;

    document.title = title;

    return () => {
      document.title = original;
    };
  }, [title]);
}
