export type LibraryID = number;

export interface BookDetails {
  Title: string;
  Description: string;
  Authors: string[];
  Cover: string;
  ISBNs: string[];
  Links: string[];
}

export interface BookEntry extends BookDetails {
  BookID: number;
  CreatedAt: string;
  CreatedBy: number;
}

export interface UserSettings {
  welcome_message_shown?: boolean;
  selected_library_id?: number;
}

export interface User {
  UserName: string | null;
  UserID: number;
  UserEmail: string;
  UserRole: string;
  DefaultLibraryID: number;
  CustomLibraryID: number;
  Settings: UserSettings;
}

export interface UserWithTakenCount extends User {
  TakenCount: number;
  WaitingCount: number;
}

export interface Library {
  LibraryID: LibraryID;
  LibraryName: string;
  LibraryLocation: string;
  WelcomeMessage: string | null;
  OrderLink: string | null;
}

export interface BookWithInstances {
  Book: BookEntry;
  Instances: BookInstanceWithStatus[];
  WaitingList: WaitingListEntry[];
  Requests: BookRequest[];
}

export interface WaitingListEntry {
  WaitingListID: number | null;
  UserID: number;
  LibraryID: number;
}

export interface BookRequest {}

export interface BookInstanceWithStatus {
  BookInstanceID: number;
  LibraryID: number;
  Label: string;
  TakenBy: number | null;
  CreatedAt: string | null;
  CreatedBy: number;
  WrittenOffAt: string | null;
  WrittenOffBy: number | null;
}

export interface PageState {
  user: UserWithTakenCount;
  selectedUser: UserWithTakenCount;
  library: Library;
  allUsers: Map<number, UserWithTakenCount>;
}

export function newBookCopy(pageState: PageState) {
  return {
    TakenBy: null,
    BookInstanceID: -1,
    CreatedBy: pageState.user.UserID,
    CreatedAt: null,
    Label: "LABEL?",
    LibraryID: pageState.library.LibraryID,
    WrittenOffBy: null,
    WrittenOffAt: null,
  };
}

export interface EditCallbacks {
  onTakeReturn(
    instance: BookInstanceWithStatus,
    update: Partial<BookInstanceWithStatus>
  ): void;
  editBook(
    updatedBook: BookEntry,
    updatedInstances: BookInstanceWithStatus[]
  ): void;
  onJoinWaitingList(book: BookWithInstances, library: Library): void;
  onLeaveWaitingList(
    book: BookWithInstances,
    library: Library,
    waitingListEntry: WaitingListEntry
  ): void;
}

export interface AddCallbacks {
  addBook(details: BookDetails, instances: BookInstanceWithStatus[]): void;
}

export interface UserWithCountCallbacks {
  onTake(user_id: number): void;
  onReturn(user_id: number): void;
  onJoinWaitingList(user_id: number): void;
  onLeaveWaitingList(user_id: number): void;
}
