import { Component } from "react";

export interface ErrorAwareState {
  loadingError?: string;
}

export const obtainToken = (props: any) => {
  return (
    (props.location.state || {}).token ||
    new URLSearchParams(window.location.hash.substr(1)).get("id_token")
  );
};

export function login() {
  localStorage.setItem("location", window.location.href);
  window.location.href =
    process.env.REACT_APP_COGNITO_UI_URL +
    "&redirect_uri=https://" +
    process.env.REACT_APP_DOMAIN +
    "/login";
}

export function handleLoadingErrors<T, S extends ErrorAwareState>(
  component: Component<any, S>,
  promise: Promise<T>
): Promise<T> {
  return promise.catch((err) => {
    console.log("Error", err);
    if (err.text) {
      err
        .text()
        .then((e: any) =>
          component.setState({ loadingError: JSON.stringify(e) })
        );
    } else {
      component.setState({
        loadingError: err ? err.toString() : "Undefined error",
      });
    }
    return Promise.reject(err);
  });
}

export function fetchOrLogin<S extends ErrorAwareState>(
  component: Component<any, S>,
  endpoint: string,
  method: string,
  body: string | null = null
): Promise<Response> {
  return handleLoadingErrors(
    component,
    fetch(endpoint, {
      method: method,
      credentials: "include",
      body: body,
    }).then((res) => {
      if (res.ok) return res;
      if (res.status === 403 || res.status === 401) {
        console.log(res);
        login();
      }
      return Promise.reject(res);
    })
  );
}

export function fetchJsonOrLogin<S extends ErrorAwareState>(
  component: Component<any, S>,
  endpoint: string,
  method: string,
  body: string | null = null
): Promise<any> {
  return fetchOrLogin(component, endpoint, method, body).then((res) =>
    res.json()
  );
}

export function fetchBatchOrLogin<T, S extends ErrorAwareState>(
  component: Component<any, S>,
  endpoint: string,
  method: string,
  id_field: (item: T) => number,
  batch_size: number,
  last_loaded_id: number = -1
): Promise<T[]> {
  const query =
    endpoint +
    "?top=" +
    batch_size.toString() +
    (last_loaded_id !== -1 ? "&skip=" + last_loaded_id.toString() : "");
  return fetchJsonOrLogin(component, query, method).then((res: any) => {
    const items = res as T[];
    if (items.length < batch_size) return items;
    const last_id = id_field(items[items.length - 1]);
    return fetchBatchOrLogin(
      component,
      endpoint,
      method,
      id_field,
      batch_size,
      last_id
    ).then((new_items) => items.concat(new_items));
  });
}

export function downloadAsFile(
  response: Response,
  type: string,
  defaultFilename: string
): Promise<any> {
  return response.blob().then((blob) => {
    const newBlob = new Blob([blob], { type: type });
    const objUrl = window.URL.createObjectURL(newBlob);

    const disposition = response.headers.get("Content-Disposition");
    const fileName = ((disposition || "").split("filename=")[1] || "").replace(
      /"/g,
      ""
    );

    const link = document.createElement("a");
    link.href = objUrl;
    link.download = fileName || defaultFilename;
    link.click();
    // For Firefox it is necessary to delay revoking the ObjectURL.
    setTimeout(() => window.URL.revokeObjectURL(objUrl), 250);
  });
}
