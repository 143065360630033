import { Select } from "@rescui/select";
import { SelectOption, SelectOptionsGroup } from "@rescui/select/lib/types";
import i18next from "i18next";
import React, { useEffect, useState } from "react";

import { User, UserWithTakenCount } from "./model";

export function UserChooser(props: {
  allUsers: Map<number, UserWithTakenCount>;
  selected: UserWithTakenCount | null;
  renderItem: (user: UserWithTakenCount | null) => string;
  showNullUser: boolean;
  libraryId: number;
  onChosen: (user: UserWithTakenCount | null) => void;
}) {
  const [version, setVersion] = useState(0);

  const localUsers: Map<number, UserWithTakenCount> = new Map(
    [...props.allUsers.entries()].filter((entry) =>
      isLocalUser(entry.at(1) as UserWithTakenCount, props.libraryId)
    )
  );
  const otherUsers: Map<number, UserWithTakenCount> = new Map(
    [...props.allUsers.entries()].filter(
      (entry) =>
        !isLocalUser(entry.at(1) as UserWithTakenCount, props.libraryId)
    )
  );

  const optionsToDisplay: SelectOptionsGroup[] = [
    {
      label: i18next.t("Local Users"),
      options: Array.from(localUsers.values())
        .filter((u) => u.UserName)
        .sort((u1, u2) => u1.UserName!.localeCompare(u2.UserName!))
        .map(toSelectOption),
    },
    {
      label: i18next.t("Other Users"),
      options: Array.from(otherUsers.values())
        .filter((u) => u.UserName)
        .sort((u1, u2) => u1.UserName!.localeCompare(u2.UserName!))
        .map(toSelectOption),
    },
  ];

  if (props.showNullUser) {
    optionsToDisplay.at(0)?.options!.unshift(toSelectOption(null));
  }

  function toSelectOption(user: UserWithTakenCount | null): SelectOption {
    return {
      label: props.renderItem(user),
      value: user ? user.UserID.toString() : "",
    };
  }

  useEffect(() => setVersion((v) => v + 1), [props.allUsers, props.selected]); // remount when users change

  return (
    <Select
      key={version}
      options={optionsToDisplay}
      value={toSelectOption(props.selected)}
      size="s"
      isSearchable={true}
      onChange={(selected: SelectOption) => {
        const value = selected.value;
        props.onChosen(
          value === "" ? null : props.allUsers.get(parseInt(value!, 10))!
        );
      }}
    />
  );
}

function isLocalUser(user: User, libraryId: number): Boolean {
  return (
    user.Settings?.selected_library_id === libraryId ||
    user.DefaultLibraryID === libraryId
  );
}
