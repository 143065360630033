import { Component } from "react";

import { fetchJsonOrLogin } from "../../Util/common";
import BookComponentList from "./BookComponentList";
import { BookWithInstances, PageState, UserWithCountCallbacks } from "./model";

interface Props {
  page_state: PageState;
  callbacks: UserWithCountCallbacks;
  endpoint: string;
  userParamName: string;
  type: string;
}

class UserBookSection extends Component<Props> {
  private create_fetch(
    user_id: number
  ): (
    last_loaded_id: number,
    batch_size: number
  ) => Promise<BookWithInstances[]> {
    return (last_loaded_id: number, batch_size: number) => {
      const url =
        process.env.REACT_APP_BOOK_API_URL + "/view/" + this.props.endpoint;

      return fetchJsonOrLogin(
        this,
        url +
          "?top=" +
          batch_size.toString() +
          "&" +
          this.props.userParamName +
          "=" +
          user_id.toString() +
          "&includeWrittenOff=" +
          (this.props.page_state.user.UserRole === "admin" ? "true" : "false") +
          (last_loaded_id !== -1 ? "&skip=" + last_loaded_id.toString() : ""),
        "GET"
      );
    };
  }

  private fetch = this.create_fetch(this.props.page_state.selectedUser.UserID);

  render() {
    return (
      <div>
        <BookComponentList
          key={
            "user_books_" + this.props.page_state.selectedUser.UserID.toString()
          }
          fetch={this.fetch}
          filter_books={(_) => true}
          page_state={this.props.page_state}
          order_link={null}
          callbacks={this.props.callbacks}
        />
      </div>
    );
  }
}

export { UserBookSection };
